import React from "react";

export default class InputCheckboxGroup extends React.Component {
  render() {
    const {isChecked, label, name, onChange} = this.props;

    return (
      <div className="input-group-class checkbox-input-group">
        <input type="checkbox" checked={isChecked} name={name} id={name} onChange={onChange}/>
        <label htmlFor={name}>
          <svg>
            <use xlinkHref="#checkmark"/>
          </svg>
          <span className="checkbox">{label}</span>
        </label>
      </div>
    );
  }
}
